html {
  box-sizing: border-box;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  text-size-adjust: 100%;
  font-size: 14px;
}
*,
*:before,
*:after {
  box-sizing: border-box;
}

*::-webkit-scrollbar {
  background-color: #101015;
  width: 4px;
  height: 4px;
}

div:hover::-webkit-scrollbar {
  width: 7px;
  height: 10px;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
  position: absolute;
}

*::-webkit-scrollbar-thumb {
  background-color: #7f8fa4;
  border-radius: 40px;
}

*::-webkit-scrollbar-track {
  background-color: #101015;
}

*::-webkit-scrollbar-corner {
  background-color: #101015;
  width: 4px;
  height: 4px;
}

* {
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

body {
  font-style: normal;
  letter-spacing: 0;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -moz-font-feature-settings: 'liga' on;
  width: 100vw;
  max-width: 100vw;
  min-height: 100vh;
  height: 100%;
  font-family: 'Source Code Pro', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    Roboto, Ubuntu, 'Helvetica Neue', sans-serif;
  font-size: 14px;
  color: #101015;
  line-height: 1.4;
  font-weight: 400;
  padding: 0;
  margin: 0;
  background: #fafafb;
}

h1,
h2,
h3,
h4 {
  font-family: 'Source Code Pro', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    Roboto, Ubuntu, 'Helvetica Neue', sans-serif;
}

h5,
h6,
input,
button,
p {
  font-family: 'Source Code Pro', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    Roboto, Ubuntu, 'Helvetica Neue', sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
}

h1 {
  font-size: 24px;
  font-weight: 700;
  line-height: 1.4;
}

h2 {
  font-size: 20px;
  font-weight: 700;
  line-height: 1.4;
}

p {
  font-weight: 400;
  font-size: 14px;
}

button {
  text-decoration: none;
  font-size: 14px;
  font-weight: 600;
  background: transparent;
  outline: none;
  border: none;
}

a {
  text-decoration: none;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
}

a:visited {
  color: inherit;
}

a > * {
  cursor: pointer !important;
}

textarea {
  line-height: 1.5;
  min-height: 80px !important;
}

.description,
.body {
  width: 80%;
}

#root {
  display: inline-block;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

figure {
  margin: 0;
}

